export default {
    home: 'HAUS',
    aboutUs: 'ÜBER UNS',
    ourProduct: 'UNSER PRODUKT',
    contact: 'KONTAKT',

    innovations: {
        title1: 'Innovationen in',
        title2: 'Optik für',
        title3: 'Fachleute',
        devicesDescription1: 'Hochwertige optische Geräte und Ausrüstungen',
        devicesDescription2: 'für Produktion und Verarbeitung. Von Linsen bis Teleskopen – alles für einen klaren Blick auf Ihre Welt.',
        info_1: "Wir sind mehr als ein Lieferant von optischen Geräten – ein innovatives Unternehmen, das sich der Weiterentwicklung in der Optik widmet.",
        info_text_blue: "Wir stellen hochwertige Produkte wie Linsen, Mikroskope, Teleskope und Ferngläser her.",
        info_1_last: " Außerdem entwickeln wir Produktionsgeräte für diese Produkte.",
        info_2: "Ob Profi auf der Suche nach fortschrittlicher Technik oder Enthusiast für zuverlässige optische Lösungen – schließen Sie sich uns an, um die Welt mit besseren optischen Lösungen zu sehen und zu verstehen.",
        advantages: {
            convenient: 'BEQUEM',
            reliability: 'SERIOSITÄT',
            quality: 'QUALITÄT',
        },
    },

    company: {
        title: 'Unser Unternehmen',
        description: 'Wir sind auf die Produktion und den Verkauf hochwertiger optischer Geräte spezialisiert, darunter Linsen, Mikroskope, Teleskope und Ferngläser. Wir entwerfen und produzieren auch innovative Geräte zur Herstellung und Verarbeitung optischer Produkte.',
    },

    mission: {
        title: 'Unsere Mission',
        description: 'Ein führender Anbieter optischer Lösungen zu sein, die den höchsten Standards an Qualität und Zuverlässigkeit entsprechen. Dank jahrzehntelanger Erfahrung und Investitionen in die neuesten Technologien sind wir in der Lage, einzigartige Produkte zu schaffen, die selbst die anspruchsvollsten Kunden zufriedenstellen.',
    },

    services: {
        title: 'Umfassende Dienstleistungen',
        description: 'Neben der Herstellung bieten wir auch umfassende Dienstleistungen für die Planung, Umsetzung und Wartung optischer Systeme an. Unsere hochqualifizierten Spezialisten führen eine gründliche Analyse der Bedürfnisse jedes Kunden durch und entwickeln optimale schlüsselfertige Lösungen.',
    },

    whyUs: {
        title: 'WARUM WIR?',
        experience: {
            title: 'Viele Jahre Erfahrung im Bereich optischer Technologien:',
            description: 'Das gesammelte Wissen und die Kompetenzen ermöglichen es uns, unseren Kunden Produkte und Lösungen anzubieten, die ihren anspruchsvollsten Bedürfnissen entsprechen.',
        },
        production: {
            title: 'Eigene Produktionsstätten und technologische Fähigkeiten:',
            description: 'Unsere Produktionsmöglichkeiten erlauben es uns, auch die herausforderndsten Ideen umzusetzen.',
        },
        recognition: {
            title: 'Internationale Anerkennung und Präsenz:',
            description: 'Durch unsere Niederlassungen und Partner in verschiedenen Ländern können wir Kunden weltweit besser bedienen.',
        },
    },

    ourproduct: { 
        title: 'UNSER PRODUKT',
        products: {
            lensesForTelescopes: {
                title: 'Produktauswahl',
                description: 'Mit unserem umfangreichen Produktportfolio und Branchenwissen bieten wir eine umfassende Auswahl an optischen Geräten, die auf Ihre individuellen Bedürfnisse zugeschnitten sind.',
            },
            lensesForMicroscopes: {
                title: 'Expertenhilfe',
                description: 'Unser erfahrenes Team bietet professionelle Unterstützung von der Produktauswahl bis zur Lieferung, um ein reibungsloses und angenehmes Einkaufserlebnis zu gewährleisten.',
            },
            lensesForBinoculars: {
                title: 'Technische Beratung',
                description: 'Wir bieten wertvolle technische Beratung zu unserer vielfältigen Palette optischer Produkte, die Ihnen helfen, die Funktionen und Vorteile zu verstehen, die Ihren spezifischen Anforderungen und Vorlieben entsprechen.',
            },
        }
    },
    office :"Büro",
    Production :"Zentrum für die Herstellung optischer Geräte",
    Center:"Zentrum für die Herstellung optischer Geräte."
};

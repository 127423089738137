import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import VueI18n from 'vue-i18n'; 


import en from './locales/en';
import de from './locales/de';

Vue.config.productionTip = false;


Vue.use(VueI18n);


const i18n = new VueI18n({
  locale: 'de', 
  messages: {
    en,
    de,
  },
});


new Vue({
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app');

export default {
    home: 'HOME',
    aboutUs: 'ABOUT US',
    ourProduct: 'SERVICES',
    contact: 'CONTACT',

    innovations: {
        title1: 'Innovations in',
        title2: 'optics for',
        title3: 'professionals',
        devicesDescription1: 'High-quality optical devices and equipment ',
        devicesDescription2: 'for production and processing. From lenses to telescopes - everything for a clear view of your world.',

        info_1: "We are not just a supplier of optical devices, but a dynamic company committed to development in the field of optics. ",
        info_text_blue: "We specialize in manufacturing highquality products such as lenses, microscopes, telescopes and binoculars",
        info_1_last: ", and we also develop equipment for their production.",
        info_2: "Whether you are a professional in search of advanced technologies or an enthusiast in need of reliable optical solutions, we invite you to join our mission to improve the way we see and understand the world through quality optical solutions.",
        advantages: {
            convenient: 'CONVENIENT',
            reliability: 'RELIABILITY',
            quality: 'QUALITY',
        },
    },

    company: {
        title: 'Our Company',
        description: 'We specialize in the production and sale of high-quality optical devices, including lenses, microscopes, telescopes, and binoculars. We also design and manufacture innovative equipment for the production and processing of optical products.',
    },

    mission: {
        title: 'Our Mission',
        description: 'To be a leading provider of optical solutions that meet the highest standards of quality and reliability. Thanks to many years of experience and investments in the latest technologies, we are able to create unique products that satisfy even the most demanding customers.',
    },

    services: {
        title: 'Comprehensive Services',
        description: 'In addition to manufacturing, we also provide comprehensive services for the design, implementation, and maintenance of optical systems. Our highly qualified specialists conduct a thorough analysis of each client\'s needs and develop optimal turnkey solutions.',
    },

    whyUs: {
        title: 'WHY US?',
        experience: {
            title: 'Many years of experience in the field of optical technologies:',
            description: 'The accumulated knowledge and competencies allow us to provide customers with products and solutions that meet their most demanding needs.',
        },
        production: {
            title: 'Own production facilities and technological capabilities:',
            description: 'Our manufacturing capabilities allow us to realize even the most challenging ideas.',
        },
        recognition: {
            title: 'International recognition and presence:',
            description: 'Having branches and partners in different countries allows you to better serve customers around the world.',
        },
    },
    ourproduct: {
        title: 'OUR PRODUCT',
        products: {
            lensesForTelescopes: {
                title: 'Product selection',
                description: 'Leveraging our extensive product portfolio and industry knowledge, we offer a comprehensive selection of optical devices customized to your individual needs',
            },
            lensesForMicroscopes: {
                title: 'Expert assistance',
                description: 'Our experienced team provides professional support from product selection to delivery, ensuring a smooth and enjoyable shopping experience.',
            },
            lensesForBinoculars: {
                title: 'Technical advice',
                description: 'We provide valuable technical advice on our diverse range of optical products, helping you understand the features and benefits that meet your specific requirements and preferences.',
            },
        },
    },
    office :"Office",
    Production :"Center for the production of optical devices",

    Center:"Center for the production of optical devices."
};

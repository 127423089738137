<template>
  <div>
    <div class="our-product-header">
      <div class="our-product-text">
        <p>{{ $t('ourproduct.title') }}</p>
      </div>
    </div>
    <div class="product-section">
      <div class="product-box">
        <div class="product-image">
          <img src="@/assets/telescopes.png" alt="Lenses for Telescopes">
        </div>
        <div class="product-title">
          <h3>{{ $t('ourproduct.products.lensesForTelescopes.title') }}</h3>
        </div>
        <div class="product-content">
          <div class="product-description">
            <p>{{ $t('ourproduct.products.lensesForTelescopes.description') }}</p>
          </div>
          <div class="button-wrapper">
            <v-btn class="contact-button" @click="scrollToSection('why-us', 'contact')">
              {{ $t('contact') }} <img src="@/assets/Vector.svg" alt="">
            </v-btn>
          </div>
        </div>
      </div>
      <div class="product-box">
        <div class="product-image">
          <img src="@/assets/microscopes.png" alt="Lenses for Microscopes">
        </div>
        <div class="product-title">
          <h3>{{ $t('ourproduct.products.lensesForMicroscopes.title') }}</h3>
        </div>
        <div class="product-content">
          <div class="product-description">
            <p>{{ $t('ourproduct.products.lensesForMicroscopes.description') }}</p>
          </div>
          <div class="button-wrapper">
            <v-btn class="contact-button" @click="scrollToSection('why-us', 'contact')">
              {{ $t('contact') }} <img src="@/assets/Vector.svg" alt="">
            </v-btn>
          </div>
        </div>
      </div>
      <div class="product-box">
        <div class="product-image">
          <img src="@/assets/binoculars.png" alt="Lenses for Binoculars">
        </div>
        <div class="product-title">
          <h3>{{ $t('ourproduct.products.lensesForBinoculars.title') }}</h3>
        </div>
        <div class="product-content">
          <div class="product-description">
            <p>{{ $t('ourproduct.products.lensesForBinoculars.description') }}</p>
          </div>
          <div class="button-wrapper">
            <v-btn class="contact-button" @click="scrollToSection('why-us', 'contact')">
              {{ $t('contact') }} <img src="@/assets/Vector.svg" alt="">
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OurProductSection',
  methods: {
    scrollToSection(sectionId, sectionName) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
        window.history.pushState(null, '', `/${sectionName}`);
      }
    },
  },
};
</script>
<style>
.our-product-header {
  width: 100%;
  height: 121px;
  background: #0095ff;
}

.our-product-text {
  margin-left: 156px;
  font-family: var(--second-family);
  font-weight: 700;
  font-size: 70px;
  line-height: 84%;
  color: #fff;
  display: flex;
  padding-top: 35px;
}

.product-section {
  display: flex;
  justify-content: space-between;
  margin: 90px 156px;
  margin-bottom: 100px;
  flex-wrap: wrap;
  gap: 20px;
}

.product-box {
  border: 1px solid #c3c3c3;
  width: 399px;
  height: 482px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
}

.product-image {
  width: 100%;
  height: 236px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.product-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.product-title {
  width: 100%;
  height: 41px;
  background-color: #0095ff;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
  position: absolute;
  top: 195px;
  left: 0;
  z-index: 2;
}

.product-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 236px);
  padding: 20px;
  padding-top: 40px;
  margin-top: auto;
}

.product-description {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #fff;
}

.button-wrapper {
  margin-top: auto;
  padding-top: 20px;
}

.contact-button {
  width: 119px;
  height: 40px;
  background-color: #0095ff !important;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;
  color: #fff !important;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  border-radius: 0 !important;
  transition: background-color 0.3s;
}

.contact-button:hover {
  background-color: #007acc;
}

.contact-button a {
  color: inherit;
  text-decoration: none;
}

@media (max-width: 1570px) {
  .product-section {
    flex-wrap: nowrap;
    justify-content: space-between;
    gap: 10px;
  }

  .product-box {
    width: 320px;
    height: 400px;
  }

  .product-image {
    height: 180px;
  }

  .product-title {
    margin-top: -20px;
    font-size: 20px;
  }

  .product-description {
    font-size: 13px;
    margin-top: -50px;
  }

  .contact-button {
    font-size: 16px;
    height: 35px;
    width: 100px;
  }
}

@media (max-width: 1300px) {
  .product-section {
    flex-wrap: wrap;
    justify-content: center;
  }

  .product-box {
    width: 399px;
    height: 482px;
  }

  .product-image {
    height: 236px;
  }

  .product-title {
    margin-top: 0;
    font-size: 24px;
  }

  .product-description {
    margin-top: 0;
    font-size: 16px;
  }

  .contact-button {
    font-size: 18px;
    height: 40px;
    width: 119px;
  }
}

@media (max-width: 1200px) {
  .our-product-text {
    margin-left: 40px;
    font-size: 60px;
  }
}

@media (max-width: 1000px) {
  .product-box {
    width: 399px;
    height: 482px;
  }

  .product-image {
    height: 200px;
  }

  .product-title {
    font-size: 24px;
  }

  .product-description {
    font-size: 16px;
  }

  .contact-button {
    font-size: 16px;
    height: 35px;
    width: 100px;
  }
}

@media (max-width: 768px) {
  .our-product-header {
    height: 100px;
  }
  
  .our-product-text {
    margin-left: 40px;
    font-size: 50px;
    padding-top: 30px;
  }
}

@media (max-width: 750px) {
  .product-section {
    margin: 20px;
  }

  .product-box {
    width: 399px;
    height: 482px;
  }

  .product-image {
    height: 236px;
  }

  .product-title {
    font-size: 24px;
  }

  .product-description {
    font-size: 16px;
  }

  .contact-button {
    font-size: 18px;
    height: 40px;
    width: 119px;
  }
}

@media (max-width: 480px) {
  .our-product-header {
    height: 80px;
  }
  
  .our-product-text {
    margin-left: 5px;
    font-size: 40px;
    padding-top: 25px;
  }
}

@media (max-width: 500px) {
  .product-box {
    width: 399px;
    height: 482px;
  }

  .product-image {
    height: 236px;
  }

  .product-title {
    font-size: 18px;
  }

  .product-description {
    font-size: 14px;
  }

  .contact-button {
    font-size: 14px;
    height: 30px;
    width: 90px;
  }
}

@media (max-width: 330px) {
  .our-product-header {
    height: 80px;
  }
  
  .our-product-text {
    margin-left: 5px;
    font-size: 32px;
    padding-top: 25px;
  }
}
</style>
<template>
    <div class="section">
        <div class="section-info">
            <div class="title">
                <img src="@/assets/logo.svg" alt="logo" class="Logo">
            </div>
            <div class="title-optical-devices">
                <p class="optical-devices">
                    <span class="optical-devices-selection"> {{ $t ('Center')}}</span> {{ $t('innovations.devicesDescription1') }} {{
                        $t('innovations.devicesDescription2') }}
                </p>
            </div>
            <div class="title-advantages">
                <div class="CONVENIENT">{{ $t('innovations.advantages.convenient') }}</div>
                <div class="RELIABILITY">{{ $t('innovations.advantages.reliability') }}</div>
                <div class="QUALITY">{{ $t('innovations.advantages.quality') }}</div>
            </div>
            <div class="photo-and-info">
                <img src="@/assets/services.png" alt="services" class="services">
                <div class="info-container">
                    <div class="line"></div> 
                    <p class="info-1">
                        {{ $t('innovations.info_1') }}<span class="info-text-blue"> {{ $t('innovations.info_text_blue') }}</span>{{ $t('innovations.info_1_last') }}
                    </p>
                    <div class="line"></div>
                    <p class="info-2">
                        {{ $t('innovations.info_2') }}
                    </p>
                    <div class="line"></div> 
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "InnovationsSection",
};
</script>

<style>
.section {
    margin-top: 115px;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0 clamp(20px, 8vw, 152px);
}

.section-info {
    display: flex !important;
    flex-direction: column !important;
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
}

.Logo {
    height: clamp(100px, 20vw, 300px); 
    margin: 10px auto; 
    display: block; 
}

.title-optical-devices {
    max-width: 1260px;   
    padding: 0 20px;
    margin-top: 20px;
}

.optical-devices {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: clamp(16px, 1.3vw, 25px);
    color: #fff;
}

.optical-devices-selection {
    font-family: var(--font-family);
    font-weight: 600 !important;
    font-size: clamp(16px, 1.3vw, 25px);
    color: #fff;
}

.title-advantages {
    margin-top: clamp(45px, 4.7vw, 90px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: clamp(15px, 2vw, 70px);
    padding: 0 20px;
    width: 100%;
    flex-wrap: nowrap;
}

.CONVENIENT,
.RELIABILITY,
.QUALITY {
    background: #0095ff;
    width: clamp(150px, 21vw, 408px);
    height: clamp(60px, 6.5vw, 125px);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: var(--second-family);
    font-weight: 700;
    font-size: clamp(16px, 2.86vw, 55px);
    line-height: 84%;
    color: #fff;
    padding: 5px 10px;
    transition: all 0.3s ease;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (max-width: 1024px) {
    .CONVENIENT,
    .RELIABILITY,
    .QUALITY {
        width: clamp(120px, 18vw, 250px);
        font-size: clamp(14px, 2.2vw, 30px);
        height: clamp(50px, 5vw, 80px);
    }
}

@media (max-width: 768px) {
    
    .section {
    margin-top: 90px;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0 clamp(20px, 8vw, 152px);
}
    .CONVENIENT,
    .RELIABILITY,
    .QUALITY {
        width: clamp(100px, 15vw, 200px);
        font-size: clamp(12px, 1.8vw, 24px);
        height: clamp(40px, 4vw, 60px);
    }
}

.photo-and-info {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 200px;
    margin-bottom: 200px;
}

.services {
    width: 894px;
    height: 671px;
    height: auto;
    margin-right: 40px;
}

.info-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 50%;
}

.info-1,
.info-2 {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 25px;
    line-height: 120%;
    color: #fff;
    margin: 0;
    padding: 0;
    margin: 35px 0;
    max-width: 650px;
}

.info-text-blue {
    font-weight: 600;
    color: #0095ff;
}

.line {
    height: 2px;
    background-color: #c3c3c3;
    display: inline-block;
    width: 100%;
    margin: 32px 0;
    margin-left: -40px;
}

@media (max-width: 1700px) {
    .section {
    margin-top: 60px;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0 clamp(20px, 8vw, 152px);
}
    .photo-and-info {
        flex-direction: column;
        align-items: center;
        margin-bottom: 60px;
        margin-top: 60px;
    }
    .section-info {
        margin-top: -50px;
        padding: 0 10px;
    }
    .services {
        width: 100%;
        max-width: clamp(400px, 80%, 700px);
        height: auto;
        margin-bottom: 20px;
        margin-right: 0;
    }

    .info-container {
        width: 100%;
        padding: 0 20px;
        text-align: center;
    }

    .line {
        width: 100%;
        margin-left: 0;
    }

    .info-1,
    .info-2 {
        font-size: clamp(14px, 3vw, 20px);
        line-height: 1.5;
        max-width: none;
        text-align: justify;
    }
}

@media (max-width: 768px) {
    .services {
        max-width: clamp(300px, 70%, 500px);
    }
    .section-info {
        margin-top: -70px;
        padding: 0 10px;
    }
    .info-1,
    .info-2 {
        font-size: clamp(12px, 3vw, 18px);
        text-align: justify;
    }
}

@media (max-width: 540px) {
    .Logo {
        height: clamp(80px, 15vw, 200px);
    }
    .section {
        padding: 0 10px; 
    }

    .section-info {
        margin-top: -70px;
        padding: 0 10px;
    }

    .title-optical-devices {
        padding: 0 10px;
    }

    .title-advantages {
        padding: 0 10px;
    }

    .info-container {
        padding: 0 10px;
    }
}

</style>

<template>
  <div id="app">
    <AppHeader/>
    <Main_Title id="main-title" />
    <About_Us id="about-us" />
    <Our_Product id="our-product" />
    <Why_Us  />
    <ContactInfo id="why-us" />
  </div>
</template>

<script>

import AppHeader from './components/AppHeader.vue';
import Main_Title from './components/main-comp/main-title.vue';
import About_Us from './components/main-comp/about-us.vue';
import Our_Product from './components/main-comp/our-product.vue';
import Why_Us from './components/main-comp/why-us.vue';
import ContactInfo from './components/main-comp/contact-info.vue'; 

export default {
  name: 'App',
  components: {
    AppHeader,
    Main_Title,
    About_Us,
    Our_Product,
    Why_Us,
    ContactInfo 
  }
}
</script>

<style>
#app{
  background-color: #151525;
}
html {
  scroll-behavior: smooth;
}

:root {
  --font-family: "Montserrat", sans-serif;
  --second-family: "RoadRadio", sans-serif;
  --third-family: "SF Pro Text", sans-serif;
}
</style>

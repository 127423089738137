<template>
    <div>
        <div class="about-us-header">
            <div class="about-us-text">
                <p>{{ $t('aboutUs') }}</p>
            </div>
        </div>

        <div class="content-section">
            <h2>{{ $t('company.title') }}</h2>
            <p>{{ $t('company.description') }}</p>
            <hr />
        </div>

        <div class="content-section">
            <h2>{{ $t('mission.title') }}</h2>
            <p>{{ $t('mission.description') }}</p>
            <hr />
        </div>

        <div class="content-section">
            <h2>{{ $t('services.title') }}</h2>
            <p>{{ $t('services.description') }}</p>
            <hr />
        </div>
    </div>
</template>

<script>
export default {
    name: "AboutUsSection",
};
</script>

<style>
.about-us-header {
    width: 100%;
    height: 121px;
    background: #0095ff;
}

.about-us-text {
    margin-left: 156px;
    font-family: var(--second-family);
    font-weight: 700;
    font-size: 70px;
    line-height: 84%;
    color: #fff;
    display: flex;
    padding-top: 35px;
}

.content-section {
    margin: 20px 156px;
    font-family: var(--second-family);
}

.content-section h2 {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 35px;
    line-height: 120%;
    color: #0095ff;
}

.content-section p {
    margin-top: 20px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 18px;
    line-height: 120%;
    color: #fff !important;
    max-width: 700px;
    margin-bottom: 40px;
}

.content-section hr {
    border: none;
    border-top: 2px solid #c3c3c3;
    margin-top: 20px;
}

@media (max-width: 1200px) {
    .about-us-text {
        margin-left: 80px;
        font-size: 60px;
    }

    .content-section {
        margin: 30px 80px !important;
    }
}

@media (max-width: 768px) {
    .about-us-header {
        height: 100px;
    }

    .about-us-text {
        margin-left: 40px;
        font-size: 50px;
        padding-top: 30px;
    }

    .content-section {
        margin: 20px 40px !important;
    }
}

@media (max-width: 480px) {
    .about-us-header {
        height: 80px;
    }

    .about-us-text {
        margin-left: 5px;
        font-size: 40px;
        padding-top: 25px;
    }

    .content-section {
        margin: 10px 5px !important;
    }
}
</style>

<template>
  <header class="header">
    <nav class="nav">
      <div class="nav-btn-container" :class="{ mobile: isMobile }">
        <v-btn class="lang-btn" :class="{ active: selectedLanguage === 'EN' }" @click="changeLanguage('EN')">EN</v-btn>
        <v-btn class="lang-btn" :class="{ active: selectedLanguage === 'DE' }" @click="changeLanguage('DE')">DE</v-btn>
      </div>

      <ul class="nav-list" v-if="!isMobile">
        <li class="nav-item" @click="scrollToSection('main-title', 'home')">{{ $t('home') }}</li>
        <li class="nav-item" @click="scrollToSection('about-us', 'aboutUs')">{{ $t('aboutUs') }}</li>
        <li class="nav-item" @click="scrollToSection('our-product', 'ourProduct')">{{ $t('ourProduct') }}</li>
        <li class="nav-item" @click="scrollToSection('why-us', 'contact')">{{ $t('contact') }}</li>
      </ul>

      <div class="burger" :class="{ 'is-active': isMenuOpen }" @click="toggleMenu">
        <span></span>
        <span></span>
        <span></span>
      </div>

      <transition name="slide">
        <div class="sidebar" v-if="isMenuOpen">
          <ul class="sidebar-list">
            <li class="nav-item" @click="scrollToSection('main-title', 'home')">{{ $t('home') }}</li>
            <li class="nav-item" @click="scrollToSection('about-us', 'aboutUs')">{{ $t('aboutUs') }}</li>
            <li class="nav-item" @click="scrollToSection('our-product', 'ourProduct')">{{ $t('ourProduct') }}</li>
            <li class="nav-item" @click="scrollToSection('why-us', 'contact')">{{ $t('contact') }}</li>
          </ul>
        </div>
      </transition>
    </nav>
  </header>
</template>

<script>
export default {
  name: "AppHeader",
  data() {
    return {
      selectedLanguage: this.$i18n.locale.toUpperCase(),
      isMenuOpen: false,
      isMobile: window.innerWidth < 1000
    };
  },
  methods: {
    changeLanguage(lang) {
      this.$i18n.locale = lang.toLowerCase();
      this.selectedLanguage = lang;
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    handleMenuItemClick() {
      this.isMenuOpen = false;
    },
    handleResize() {
      this.isMobile = window.innerWidth < 1000;
      if (!this.isMobile) {
        this.isMenuOpen = false;
      }
    },
    scrollToSection(sectionId, sectionName) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });

        // Оновлюємо URL без # за допомогою pushState
        window.history.pushState(null, '', `/${sectionName}`);
      }
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>


<style>
.header {
  padding-top: 65px;
  position: relative;
  height: 120px;
}

.nav {
  display: flex;
  align-items: center;
  position: fixed;
  justify-content: space-between;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #151525;
  padding: 60px 155px;
  z-index: 100;
}

.logo {
  padding-right: 195px;
}

.nav-list {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-right: auto;
}

.nav-btn-container {
  display: flex;
  gap: 10px;
  position: fixed;
  right: 155px;
  z-index: 101;
}

.nav-item {
  color: #FFFFFF;
  font-family: var(--second-family);
  font-weight: 500;
  margin-right: 100px;
  font-size: 18px;
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  align-items: center;
  position: relative;
}

.nav-item a {
  text-decoration: none;
  color: inherit;
  background: transparent;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.nav-item::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -3px;
  width: 100%;
  height: 2px;
  background-color: #0095FF;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.nav-item:hover::after {
  transform: scaleX(1);
}

.nav-item:last-child {
  margin-right: 0;
}

.lang-btn {
  border: 2px solid #0095FF;
  border-radius: 0;
  padding: 5px 15px;
  color: #0095FF !important;
  background-color: transparent;
  transition: background-color 0.3s ease;
  font-weight: 500;
  background-color: transparent !important;
}

.lang-btn:hover,
.lang-btn.active {
  background-color: #0095FF !important;
  color: #fff !important;
}


.burger {
  display: none;
}

@media(max-width: 1700px) {
  .logo {
    padding-right: 140px;
  }
}

@media(max-width: 1600px) {
  .logo {
    padding-right: 110px;
  }
}

@media(max-width: 1350px) {


  .nav-item {
    color: #FFFFFF;
    font-family: var(--second-family);
    font-weight: 400;
    margin-right: 60px;
    font-size: 16px;
    cursor: pointer;
    white-space: nowrap;
    display: flex;
    align-items: center;
    position: relative;
  }
}

@media (max-width: 1101px) {



  .nav-btn-container {
    position: fixed;
    right: 140px;
    top: 50px;
    z-index: 1000;

  }

  .burger span {
    display: block;
    width: 25px;
    height: 3px;
    background-color: #0095FF;
    border-radius: 3px;
    position: absolute;
    transition: all 0.3s ease-in-out;
  }

  .burger span:first-child {
    transform: translateY(-8px);
  }

  .burger span:last-child {
    transform: translateY(8px);
  }

  .burger.is-active span {
    background-color: #0095FF;
  }

  .burger.is-active span:first-child {
    transform: rotate(45deg);
  }

  .burger.is-active span:nth-child(2) {
    opacity: 0;
  }

  .burger.is-active span:last-child {
    transform: rotate(-45deg);
  }

  .nav-btn-container.mobile {
    right: 90px;
    left: auto;
  }

}

@media (max-width: 1000px) {
  .burger {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: fixed;
    right: 50px;
    top: 55px;
    width: 30px;
    height: 25px;
    z-index: 999;
  }

  .nav {
    position: fixed;
    padding-left: max(20px, calc(120px - (1000px - 100vw) * 0.2));
  }

}

@media (max-width: 500px) {
  .burger {
    width: 24px;
    height: 20px;
    right: 32px;
    top: 47px;
  }

  .nav-btn-container {
    position: fixed;
    right: 70px !important;
    top: 43px;
    z-index: 1000;
  }

  .lang-btn {
    min-width: 40px !important;
    padding: 0px 10px !important;
    height: 30px !important;
    padding: 0;
    font-size: 12px;
    border-width: 1px;
  }

  .nav-btn-container {
    gap: 5px;

  }
}


.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 998;
  padding-top: 120px;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: white;
  z-index: 998;
  padding-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s ease-in-out;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(-100%);
}

.slide-enter-to {
  transform: translateX(0);
}

.sidebar-list {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  text-align: center;
}

.sidebar-list .nav-item {
  margin: 20px 0;
  padding: 10px 0;
  text-align: center;
  font-size: 14px;
  display: block;
  font-weight: 400;
}

.sidebar-list .nav-item a {
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #000;
}

.sidebar-list .nav-item::after {
  display: none;
}
</style>
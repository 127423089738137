<template>
  <div class="contact-container">
    <div class="contact-column addresses">
      <div class="contact-item">{{ $t('Production') }} - <a href="https://maps.app.goo.gl/1vAKeFmKBACyRXTC8">Erlinsbacherstrasse 24, 5013 Niedergösgen</a></div>

      <div class="contact-item">{{ $t('office') }} - Pendrocan Holding GmbH Rathausgasse 31, 5000 Aarau</div>
    </div>
    <div class="contact-column contacts">
      <div class="contact-item">office@pendrocan-holding.ch</div>
      <div class="contact-item">info@pendrocan-holding.ch</div>
      <div class="contact-item">+41 76 404 01 19</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactInfo',
};
</script>

<style>
.contact-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #151525;
  padding: 50px 160px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 18px;
  line-height: 1.5;
  color: #fff;
  gap: 20px;
  position: relative;
}

.addresses {
  position: relative;
}

.addresses::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%; 
  height: 1px;
  background-color: #00aaff;
  display: none; 
}

.contacts {
  position: relative;
  padding-left: 40px;
}

.contacts::after {
  content: '';
  position: absolute;
  top: 0;
  left: -20px; 
  width: 1px;
  height: 100%;
  background-color: #00aaff;
}

.contact-item {
  color: #ffffff;
  padding: 10px;

}

.addresses .contact-item {
  white-space: normal; 
  word-wrap: break-word; 
}

.contacts .contact-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; 
}

@media screen and (max-width: 1200px) {
  .contact-container {
    padding: 40px 80px;
    gap: 20px;
  }

  .contacts {
    padding-left: 20px;
  }
}

@media screen and (max-width: 940px) {
  .contact-container {
    flex-direction: column;
    padding: 40px 75px;
  }

  .contact-column {
    padding: 0;
  }

  .addresses {
    padding-bottom: 20px;
  }

  .contacts {
    padding-top: 20px;
  }

  .contacts::after {
    display: none;
  }

  .addresses::after {
    display: block;
    bottom: -20px; 
    width: 100%;
    height: 2px; 
    background-color: #00aaff;
  }
}
@media screen and (max-width: 769px){
  .contact-container {

    padding: 40px 40px;
  }
}
@media screen and (max-width: 480px) {
  .contact-container {
    padding: 0;
  }

  .addresses::after {
    display: block;
  }

  .contacts::after {
    display: none;
  }

  .contact-item {
    font-size: 16px;
  }
}




.contact-container a {
  text-decoration: none;
  color: inherit;
}

.contact-container a:hover {
  text-decoration: underline;
}

</style>
